#app {
    min-height: 100vh;
    width: 100%;
}

body {
    margin: 0;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
